import { useMemo } from 'react';

import { USER_PERMISSIONS, USER_ROLE } from '~served/configs';
import { Role } from '~served/types/db';

import { useAuthCache } from '~/queries/useAuthCache';
import { Role_Permissions, Role_Type } from '~/types/__generated/gql/graphql';

const useUserPermissions = () => {
	const { data: auth } = useAuthCache();

	const isSuperAdmin = auth ? auth.type === Role_Type.SuperAdmin : false;
	const isAdmin = auth ? auth.type === Role_Type.Admin : false;
	const isManager = auth ? auth.type === Role_Type.Manager : false;
	const isSupervisor = auth ? auth.type === Role_Type.Supervisor : false;
	const isStaff = auth ? auth.type === Role_Type.Staff : false;
	const isJuniorStaff = auth ? auth.type === Role_Type.JuniorStaff : false;

	// Allow for any role that is logged in
	const isAuthenticated = isSuperAdmin || isAdmin || isManager || isSupervisor || isStaff || isJuniorStaff;

	const PERMISSIONS = {
		AUTO_LOGOUT_TIME: getLogoutTime(auth?.type, auth?.permissions),
		ADJUST_RAW_MATERIAL_RECEIVED: isSuperAdmin || isAdmin,
		CANCEL_PAID_ORDER: isSuperAdmin || isAdmin,
		EDIT_BILL_PRINTED_ORDER: isSuperAdmin || isAdmin,
		EDIT_PAID_ORDER: isSuperAdmin || isAdmin || isManager,
		EDIT_UNPAID_ORDER: isAuthenticated,
		LOCATION_MENU_ACCESS: isSuperAdmin || isAdmin,
		LOCATION_DELETE: isSuperAdmin,
		LOCATION_GET_QR: isSuperAdmin,
		LOCATION_OPEN_CONSUMER_APP: isSuperAdmin,
		LOCATION_LOCATION_GROUP: isSuperAdmin,
		PRINT_LOG_ADJUSTMENT_VALUE: isSuperAdmin,
		PRINT_LOG_DISCOUNT: isSuperAdmin || isAdmin || isManager,
		PRINT_LOG_OFFER: isSuperAdmin || isAdmin || isManager,
		PRINT_LOG_VAT: isSuperAdmin || isAdmin || isManager,
		PRINT_LOG_PAYMENT_TYPE: isSuperAdmin || isAdmin || isManager || isSupervisor || isStaff,
		PRINT_LOG_PAYMENT_AMOUNT: isSuperAdmin || isAdmin || isManager,
		PRINT_LOG_RECEIPT_ID: isSuperAdmin || isAdmin || isManager || isSupervisor || isStaff,
		PRINT_LOG_SC: isSuperAdmin || isAdmin || isManager,
		SETTINGS_PAGE_ACCESS: isSuperAdmin || isAdmin,
		SETTINGS_CHECKLIST: isSuperAdmin,
		SETTINGS_TICKET: isSuperAdmin,
		SETTINGS_ORDER_COMMENT: isSuperAdmin,
		SETTINGS_VAT: isSuperAdmin || isAdmin,
		SETTINGS_SC: isSuperAdmin || isAdmin,
		SETTINGS_ITEM_TYPE: isSuperAdmin || isAdmin,
		SETTINGS_PAYMENT_TYPE: isSuperAdmin || isAdmin,
		SETTINGS_SHIFTS: isSuperAdmin || isAdmin,
		SETTINGS_LOCATION_GROUP: isSuperAdmin,
		SETTINGS_PRINTER: isSuperAdmin,
		TILL_PRICE_ADJUSTMENT: isSuperAdmin || isAdmin || isManager,
		TILL_EXCHANGE_RATE: isSuperAdmin,
		FLOORPLAN_UPDATE: isSuperAdmin || isAdmin,
		SETTINGS_ADD_ONS: isSuperAdmin,
	};

	return PERMISSIONS;
};

const useUserRulePermissions = () => {
	const { data: auth } = useAuthCache();

	const permissionsDictionary: Record<keyof typeof USER_PERMISSIONS, boolean> = useMemo(() => {
		const dictionary = Object.keys(USER_PERMISSIONS).reduce((acc, key) => {
			acc[key] = false;
			return acc;
		}, {} as Record<keyof typeof USER_PERMISSIONS, boolean>);

		if (auth) {
			auth.permissions.forEach((permission) => {
				dictionary[permission] = true;
			});
		}

		return dictionary;
	}, [auth]);

	return permissionsDictionary;
};

export { useUserPermissions, useUserRulePermissions };

const getLogoutTime = (type?: Role['type'], permissions?: Role_Permissions[]) => {
	const isAdmin = type === USER_ROLE.SUPER_ADMIN || type === USER_ROLE.ADMIN;
	const isAutoLogout = permissions?.find((str) => str === Role_Permissions.AutoLogout) ? true : false;

	if (isAdmin && !isAutoLogout) return 604800000; // 1 week
	return 180000; // 3 mins
};

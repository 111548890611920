import { Client } from 'graphql-ws';

import { Data_Event_Type, SubscribeToVenueLocationsSubscription } from '~/types/__generated/gql/graphql';
import { GetLocationByIdResults, getLocationByIdQueryKey } from '../useGetLocationById';
import { GetOperationalLocationsResults, getOperationalLocationsKey } from '../useGetOperationalLocations';

const query = /* GraphQL */ `
	subscription SubscribeToVenueLocations {
		subscribeToVenueLocations {
			type
			id
			payload {
				_id
				created_at
				updated_at
				venue
				name
				type
				payment_types
				status
				hash
				no_vat
				no_service_charge
				is_pay_later_allowed
				is_pay_now_allowed
				locations_groups_tags
				room_number
				default_order_note
				hidden_items_configs {
					items
					item_types
					categories
				}

				_current_orders {
					_id
					created_at
					updated_at
					index
					items {
						_id
						category
						item
						title
						original_price
						minimum_required_price
						original_price_addons {
							vat {
								percentage
								amount
							}
							service_charge {
								percentage
								amount
							}
						}
						unit_price
						listed_price
						quantity
						extra_quantity
						serving_quantity
						comments
						cancelled_reason
						recipe {
							raw_material
							unit_of_measurement
							consumed_unit_of_measurement
							consumed_quantity
						}
						options {
							comments
							_id
							option
							uid
							title
							quantity
							original_price
							original_price_addons {
								vat {
									percentage
									amount
								}
								service_charge {
									percentage
									amount
								}
							}
							listed_price
							recipe {
								raw_material
								unit_of_measurement
								consumed_unit_of_measurement
								consumed_quantity
							}
							is_hide_from_receipt
						}
						type
						delivered_count
						status
						prep_time
						printer_tag
						subtotal
						subtotal_addons {
							offer {
								metadata
								amount
							}
							discount {
								is_divided
								type
								value
								amount
							}
							vat {
								is_included
								percentage
								amount
							}
							service_charge {
								is_included
								percentage
								amount
							}
							adjustment {
								amount
							}
						}
						net_amount
						gross_amount
						cancelled_amount
						no_vat
						no_service_charge
						is_paid
					}
					cancelled_items {
						_id
						category
						item
						title
						original_price
						minimum_required_price
						original_price_addons {
							vat {
								percentage
								amount
							}
							service_charge {
								percentage
								amount
							}
						}
						unit_price
						listed_price
						quantity
						extra_quantity
						serving_quantity
						comments
						cancelled_reason
						recipe {
							raw_material
							unit_of_measurement
							consumed_unit_of_measurement
							consumed_quantity
						}
						options {
							comments
							_id
							option
							uid
							title
							quantity
							original_price
							original_price_addons {
								vat {
									percentage
									amount
								}
								service_charge {
									percentage
									amount
								}
							}
							listed_price
							recipe {
								raw_material
								unit_of_measurement
								consumed_unit_of_measurement
								consumed_quantity
							}
							is_hide_from_receipt
						}
						type
						delivered_count
						status
						prep_time
						printer_tag
						subtotal
						subtotal_addons {
							offer {
								metadata
								amount
							}
							discount {
								is_divided
								type
								value
								amount
							}
							vat {
								is_included
								percentage
								amount
							}
							service_charge {
								is_included
								percentage
								amount
							}
							adjustment {
								amount
							}
						}
						net_amount
						gross_amount
						cancelled_amount
						no_vat
						no_service_charge
						is_paid
					}
					prepared_at
					processed_at
					paid_at
					cancelled_at
					prepared_by
					processed_by
					paid_by
					cancelled_by
					user
					customer
					venue
					status
					is_paid
					payway_ref
					payway_verification_data
					staff
					original_orders
					cancelled_reason
					source
					device
					prep_time
					currencies_configs
					location
					current_location
					max_prepare_time
					items_count
					items_count_by_types
					prepped_count
					subtotal
					offer_amount
					discount_amount
					net_amount
					vat_amount
					service_charge_amount
					adjustment_amount
					grand_total
					gross_amount
					cancelled_amount
					receipt
					is_needing_prep_time_confirmation
					note
					is_cancelled
					is_bill_printed
					is_needing_payment_confirmation
					ticket_linebreaks
				}
			}
		}
	}
`;

export const subscribeToVenueLocations = async (client: Client) => {
	const subscription = client.iterate<SubscribeToVenueLocationsSubscription>({
		query: query,
	});
	for await (const result of subscription) {
		if (result.data) {
			const { type, payload } = result.data.subscribeToVenueLocations;

			switch (type) {
				case Data_Event_Type.Create: {
					window.$queryClient?.setQueryData<GetOperationalLocationsResults>(
						getOperationalLocationsKey(),
						(oldData) => {
							if (!oldData) return [];
							if (!payload) return oldData;

							return [payload, ...oldData];
						}
					);
					break;
				}
				case Data_Event_Type.Update: {
					window.$queryClient?.setQueryData<GetOperationalLocationsResults>(
						getOperationalLocationsKey(),
						(oldData) => {
							if (!oldData) return [];
							if (!payload) return oldData;

							return oldData.map((o) => (o._id === payload._id ? payload : o));
						}
					);
					break;
				}
				case Data_Event_Type.Delete: {
					window.$queryClient?.setQueryData<GetOperationalLocationsResults>(
						getOperationalLocationsKey(),
						(oldData) => {
							if (!oldData) return [];
							if (!payload) return oldData;

							return oldData.filter((o) => o._id !== payload._id);
						}
					);
					break;
				}
			}

			if (payload)
				window.$queryClient?.setQueryData<GetLocationByIdResults>(
					getLocationByIdQueryKey(payload._id),
					(prev) => (prev ? { ...prev, ...payload } : prev)
				);
		}
	}
};
